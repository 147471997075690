import React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';
//import linkResolver from 'utils/linkResolver';
import Layout from 'components/layout';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return 'Not a preview!';

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'ovoenergycareers',
  linkResolver: ({ node, key, value }) => doc => {
    // Pretty URLs for known types
    if (doc.type === 'department') return `/department/${doc.uid}`;
    // Fallback for other types, in case new custom types get created
    if (doc.type === 'homepage') return '/';
    return `/${doc.uid}`;
  },
});
